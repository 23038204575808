<template>
	<div>
		<div class="js-copy js-content pd_body">
			<headerNav :currentPath="'DataEra'" :isScrolled="isScrolled"></headerNav>
			<headerTop
				class="headertop"
				:type="'2'"
				:language="this.$route.params.type"
			></headerTop>
			<div class="content-box content-box-pc content-box-mobile">
				<div class="img-box margin-pc margin-mobile">
					<img
						class="h5-img"
						src="https://res.metaera.hk/resources/250226/174055005967beafab49d19.jpg"
					/>
					<img
						class="web-img"
						src="https://res.metaera.hk/resources/250226/174055007867beafbe90265.jpg"
					/>
				</div>
				<div class="chart-wraper margin-pc margin-mobile">
					<div class="myleftCharts" id="myleftCharts"></div>
					<div class="table-box margin-right-pc margin-right-mobile0">
						<el-table
							:data="tableRightData"
							fit
							:cell-class-name="customClass"
							:row-class-name="tableRowClassName"
						>
							<el-table-column
								v-for="(item, ind) in tableRightColumn"
								:prop="item.prop"
								:label="item.label"
								:key="ind"
								:min-width="
									isMobileDevice
										? ind == 0
											? '100'
											: ind == 1
											? '60'
											: '80'
										: ind == 0
										? '100'
										: ind == 1
										? '60'
										: '80'
								"
								:class="{ cell_active: ind == 0 }"
							>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<div class="dataera_type">
					<div
						class="dataera_type_item pointer"
						v-for="(item, index) in getTypeArray()"
						:key="index"
						:class="{ dataera_type_item_active: index == currentType - 1 }"
						@click="handleClick(index)"
					>
						<div
							class="dataera_type_item_name_left"
							v-if="index == currentType - 1"
						>
							<div class="dataera_type_item_name_left_block"></div>
						</div>

						<div
							class="dataera_type_item_name"
							:class="{
								dataera_type_item_name_active: index == currentType - 1,
							}"
						>
							{{ item.name }}
						</div>
						<div
							class="dataera_type_item_name_right"
							v-if="index == currentType - 1"
							><div class="dataera_type_item_name_right_block"></div
						></div>
					</div>
				</div>

				<div class="table-box margin-pc margin-mobile0">
					<el-table
						:data="tableData"
						style="width: 100%"
						fit
						:cell-class-name="customClass"
						:row-class-name="tableRowClassName1"
					>
						<el-table-column
							v-if="!isMobileDevice"
							type="index"
							label="NO."
							width="80px"
						>
							<template slot-scope="scope">
								{{ scope.$index + 1 }}
							</template>
						</el-table-column>
						<el-table-column
							v-for="(item, ind) in getTableColumn()"
							:prop="item.prop"
							:label="item.label"
							:key="ind"
							:min-width="
								isMobileDevice
									? ind == 0
										? '100'
										: ind == getTableColumn().length - 1
										? '80'
										: '60'
									: ind == 0
									? '200'
									: ind == getTableColumn().length - 1
									? '100'
									: '80'
							"
							:class="{ cell_active: ind == 0 }"
						>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import headerTop from "@/components/pc/headern.vue";
("");
import { getBtcList } from "@/api/home";
// import Chart from "chart.js/auto";
import * as echarts from "echarts";
import { newBus } from "../../components/pc/headern";

export default {
	name: "",
	components: { headerNav, headerTop },
	data() {
		return {
			isMobileDevice: window.screen.width < 900,
			isScrolled: false,
			osafai: false,
			olead: false,
			topChart: null,
			currentNum: 0,
			maxNum: 0,
			localLanguage: 0,
			currentType: 1,

			tableRightColumn: [
				{
					prop: "name",
					label: this.localLanguage == 2 ? "" : "类别",
				},
				{
					prop: "count",
					label: this.localLanguage == 2 ? "" : "数量",
				},
				{
					prop: "showNum",
					label: this.localLanguage == 2 ? "" : "BTC持有量",
				},
				{
					prop: "proportion",
					label: this.localLanguage == 2 ? "" : "总量占比",
				},
			],
			tableData: [],
			tableRightData: [],
			allList: [],
		};
	},
	created() {},

	mounted() {
		this.showSel = 0;
		var type = this.$route.params.type;
		console.log("type:" + type);

		let localLanguage = sessionStorage.getItem("localLanguage");
		if (type == "tc") {
			sessionStorage.setItem("localLanguage", "繁体中文");
			this.localLanguage = 1;
		} else if (type == "en") {
			sessionStorage.setItem("localLanguage", "english");
			this.localLanguage = 2;
		} else {
			sessionStorage.setItem("localLanguage", "简体中文");
			this.localLanguage = 0;
		}

		const that = this;
		window.onresize = () => {
			return (() => {
				that.isMobileDevice = window.screen.width < 900;
			})();
		};
		this.getBtcListData();

		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			var type = "";
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
				type = "tc";
				this.$router.replace({
					name: "/DataEra", // 可选，保持当前路径可以省略
					params: {
						type: type,
					},
				});
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
				type = "en";
				this.$router.replace({
					name: "/DataEra", // 可选，保持当前路径可以省略
					params: {
						type: type,
					},
				});
			} else {
				this.localLanguage = 0;
				type = "";
				this.$router.replace({
					path: "/DataEra",
				});
			}

			console.log("localLanguage" + localLanguage);
			this.getBtcListData();
		});
	},
	methods: {
		getTypeArray() {
			return [
				{
					name: this.localLanguage == 2 ? "Public Co." : "上市公司",
					type: 1,
				},
				{
					name: this.localLanguage == 2 ? "Private Co." : "私人公司",
					type: 2,
				},
				{
					name: this.localLanguage == 2 ? "Governments" : "政府",
					type: 3,
				},
				{
					name: "DeFi",
					type: 4,
				},
				{
					name: "ETF",
					type: 5,
				},
			];
		},
		getTableColumn() {
			if (this.currentType == 1) {
				return [
					{
						prop: "company",
						label: this.localLanguage == 2 ? "Name" : "名称",
					},
					{
						width: 74,
						prop: "symbol",
						label: this.localLanguage == 2 ? "Symbol" : "股票代码",
					},
					{
						prop: "country",
						label: this.localLanguage == 2 ? "Country" : "国家",
						width: 80,
					},
					{
						prop: "showNum",
						label: this.localLanguage == 2 ? "Bitcoin" : "BTC持有量",
						width: 90,
					},
					{
						prop: "proportion",
						label: this.localLanguage == 2 ? "/21M" : "总量占比",
						width: 90,
					},
				];
			} else if (this.currentType == 2) {
				return [
					{
						prop: "company",
						label: this.localLanguage == 2 ? "Name" : "名称",
					},
					{
						prop: "country",
						label: this.localLanguage == 2 ? "Country" : "国家",
						width: 80,
					},
					{
						prop: "showNum",
						label: this.localLanguage == 2 ? "Bitcoin" : "BTC持有量",
						width: 90,
					},
					{
						prop: "proportion",
						label: this.localLanguage == 2 ? "/21M" : "总量占比",
						width: 90,
					},
				];
			} else if (this.currentType == 3) {
				return [
					{
						prop: "company",
						label: this.localLanguage == 2 ? "Name" : "名称",
					},
					{
						prop: "showNum",
						label: this.localLanguage == 2 ? "Bitcoin" : "BTC持有量",
						width: 90,
					},
					{
						prop: "proportion",
						label: this.localLanguage == 2 ? "/21M" : "总量占比",
						width: 90,
					},
				];
			} else if (this.currentType == 4) {
				return [
					{
						prop: "company",
						label: this.localLanguage == 2 ? "Name" : "名称",
					},

					{
						prop: "showNum",
						label: this.localLanguage == 2 ? "Bitcoin" : "BTC持有量",
						width: 90,
					},
					{
						prop: "proportion",
						label: this.localLanguage == 2 ? "/21M" : "总量占比",
						width: 90,
					},
				];
			} else if (this.currentType == 5) {
				return [
					{
						prop: "company",
						label: this.localLanguage == 2 ? "Name" : "名称",
					},
					{
						width: 74,
						prop: "symbol",
						label: this.localLanguage == 2 ? "Symbol" : "股票代码",
					},
					{
						prop: "country",
						label: this.localLanguage == 2 ? "Country" : "国家",
						width: 80,
					},
					{
						prop: "showNum",
						label: this.localLanguage == 2 ? "Bitcoin" : "BTC持有量",
						width: 90,
					},
					{
						prop: "proportion",
						label: this.localLanguage == 2 ? "/21M" : "总量占比",
						width: 90,
					},
				];
			}
		},
		handleClick(type) {
			this.currentType = type + 1;
			this.getCurrentBtcList();
		},
		customClass({ row, column, rowIndex, columnIndex }) {
			return `cell${column.property}`;
		},
		getBtcListData() {
			getBtcList({})
				.then((res) => {
					if (res.code == 200) {
						const list = res.data.list;
						this.allList = list;
						this.currentNum = res.data.currentNum;
						this.maxNum = res.data.maxNum;

						var tableLeftData = [];
						this.getCurrentBtcList();

						var list5 = list["5"];
						var num5 = 0;
						for (var i = 0; i < list5.length; i++) {
							num5 = num5 + Number(list5[i].count);
						}

						tableLeftData.push({
							name: "ETF",
							value: num5.toFixed(2),
							showNum: this.formatNumber(num5.toFixed(2)),
							count: list5.length,
							proportion: ((num5 / res.data.maxNum) * 100).toFixed(3) + "%",
							itemStyle: {
								color: "#9BDDC7",
							},
						});

						var list1 = list["1"];
						var num1 = 0;
						for (var i = 0; i < list1.length; i++) {
							num1 = num1 + Number(list1[i].count);
						}

						tableLeftData.push({
							name: this.localLanguage == 2 ? "Public Co." : "上市公司",
							value: num1.toFixed(2),
							count: list1.length,
							showNum: this.formatNumber(num1.toFixed(2)),
							proportion: ((num1 / res.data.maxNum) * 100).toFixed(3) + "%",
							itemStyle: {
								color: "#FF8585",
							},
						});

						var list3 = list["3"];
						var num3 = 0;
						for (var i = 0; i < list3.length; i++) {
							num3 = num3 + Number(list3[i].count);
						}
						tableLeftData.push({
							name: this.localLanguage == 2 ? "Governments" : "政府",
							value: num3.toFixed(2),
							count: list3.length,
							showNum: this.formatNumber(num3.toFixed(2)),
							proportion: ((num3 / res.data.maxNum) * 100).toFixed(3) + "%",
							itemStyle: {
								color: "#FFAC88",
							},
						});

						var list2 = list["2"];
						var num2 = 0;
						for (var i = 0; i < list2.length; i++) {
							num2 = num2 + Number(list2[i].count);
						}
						tableLeftData.push({
							name: this.localLanguage == 2 ? "Private Co." : "私人公司",
							value: num2.toFixed(2),
							count: list2.length,
							showNum: this.formatNumber(num2.toFixed(2)),
							proportion: ((num2 / res.data.maxNum) * 100).toFixed(3) + "%",
							itemStyle: {
								color: "#6BD5DD",
							},
						});

						var list4 = list["4"];
						var num4 = 0;
						for (var i = 0; i < list4.length; i++) {
							num4 = num4 + Number(list4[i].count);
						}
						tableLeftData.push({
							name: "DeFi",
							value: num4.toFixed(2),
							count: list4.length,
							showNum: this.formatNumber(num4.toFixed(2)),
							proportion: ((num4 / res.data.maxNum) * 100).toFixed(3) + "%",
							itemStyle: {
								color: "#FFCDA4",
							},
						});

						tableLeftData.push({
							name: this.localLanguage == 2 ? "Other" : "其他",
							value: (
								res.data.maxNum -
								num1 -
								num2 -
								num3 -
								num4 -
								num5
							).toFixed(2),
							itemStyle: {
								color: "#DCDCDC",
							},
						});
						this.renderceshi(tableLeftData);

						this.tableRightData = tableLeftData.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		formatNumber(value) {
			if (!value) return "0";

			// 将数字按小数点分割为整数部分和小数部分
			const [integerPart, decimalPart] = value.toString().split(".");
			// 对整数部分每三位加逗号
			const formattedInteger = integerPart.replace(
				/\B(?=(\d{3})+(?!\d))/g,
				","
			);
			// 如果有小数部分，则拼接小数部分，否则只返回整数部分
			return decimalPart
				? `${formattedInteger}.${decimalPart}`
				: formattedInteger;
		},

		getCurrentBtcList() {
			var list = this.allList[this.currentType];
			for (var i = 0; i < list.length; i++) {
				if ((list[i].proportion + "").indexOf("%") != -1) {
				} else {
					list[i].proportion = (100 * list[i].proportion).toFixed(3) + "%";
				}

				list[i].name = list[i].company;
				list[i].value = list[i].count;
				list[i].showNum = this.formatNumber(list[i].count.toFixed(2));
			}
			this.tableData = list;
		},
		showUrl(url) {
			window.open(url, "");
		},
		renderceshi(dataleft) {
			var chartDom = document.getElementById("myleftCharts");

			var myChart = echarts.init(chartDom);

			var option;

			option = {
				title: [
					{
						text:
							this.localLanguage == 2
								? "Bitcoin Distribution Overview"
								: "比特币持有分布总览", // 图例顶部的标题
						left: "10%",
						top: "5%",
						textStyle: {
							fontSize: 20,
							fontWeight: 600,
							color: this.isMobileDevice ? "#fff" : "#000",
						},
					},
				],
				tooltip: {
					trigger: "item",
				},
				legend: {
					top: "15%",
					orient: "vertical",
					left: "right",
					textStyle: {
						color: this.isMobileDevice ? "#fff" : "#000",
					},
				},
				series: [
					{
						name: "持币数据",
						type: "pie",
						radius: ["40%", "70%"],
						center: ["30%", "55%"], // 饼状图的中心位置（水平 50%，垂直 60%）
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: "center",
						},
						// 立体效果
						itemStyle: {
							shadowBlur: 20, // 模糊程度
							shadowColor: "rgba(0, 0, 0, 0.5)", // 阴影颜色
							shadowOffsetX: 5, // X轴偏移
							shadowOffsetY: 5, // Y轴偏移
							borderColor: "#fff", // 分割线颜色
							borderWidth: 1, // 分割线宽度
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 30,
								shadowColor: "rgba(0, 0, 0, 0.7)",
							},
							label: {
								show: true,
								fontSize: 20,
								fontWeight: "bold",
							},
						},
						labelLine: {
							show: false,
						},
						data: dataleft,
					},
				],
			};
			option && myChart.setOption(option);
		},
		// 动态设置行的类名
		tableRowClassName({ rowIndex }) {
			if (this.isMobileDevice) {
				if (rowIndex % 2 === 0) {
					return "even-row-m"; // 偶数行
				} else {
					return "odd-row-m"; // 奇数行
				}
			} else {
				if (rowIndex % 2 === 0) {
					return "even-row"; // 偶数行
				} else {
					return "odd-row"; // 奇数行
				}
			}
		},

		// 动态设置行的类名
		tableRowClassName1({ rowIndex }) {
			if (this.isMobileDevice) {
				if (rowIndex % 2 === 0) {
					return "even-row-m1"; // 偶数行
				} else {
					return "odd-row-m1"; // 奇数行
				}
			} else {
				if (rowIndex % 2 === 0) {
					return "even-row"; // 偶数行
				} else {
					return "odd-row"; // 奇数行
				}
			}
		},
	},
};
</script>
<style scoped>
.headertop {
	display: block;
}
.chart-wraper {
	margin-top: 20px;
	margin-bottom: 40px;
	display: flex;
}
.myleftCharts {
	/* width: calc(100vw * 540 / 1200); */
	width: 47%;
	/* height: calc(100vw * 180 / 1200); */
	height: 300px;
	background: #fff;
	border-radius: 20px;
	padding-right: 30px;
}
.myrightCharts {
	margin-top: 0px;
	margin-left: 5%;
	width: 47%;
	/* width: calc(100vw * 360 / 1200); */
	/* height: calc(100vw * 180 / 1200); */
	height: 300px;
	background: #fff;
	border-radius: 20px;
	padding-right: 30px;
}
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #f4f4f4;
}
.content-box-pc {
	margin-left: 180px;
	padding-bottom: 50px;
}
.margin-pc {
	margin-left: 38px;
	margin-right: 52px;
}

.margin-right-pc {
	margin-top: 0px;
	margin-left: 3%;
	width: 47%;
}
.img-box {
	height: calc(100vw * 180 / 1200);
	position: relative;
}

.img-box img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 6px;
}
.web-img {
	display: block;
}
.h5-img {
	display: none;
}

@media screen and (max-width: 900px) {
	.js-copy {
		background: #0f0f0f;
	}
	.headertop {
		display: none;
	}
	.content-box-mobile {
		margin-left: 0;
		margin-top: 65px;
	}
	.margin-mobile {
		margin-left: 15px;
		margin-right: 15px;
	}

	.margin-mobile0 {
		margin-left: 15px;
		margin-right: 0;
	}

	.margin-right-mobile0 {
		margin-top: 20px;
		margin-left: 0;
	}
	.table-box {
		width: calc(100vw - 30px);
		background: #fff;
		padding-left: 0px;
		padding-right: 0px;
	}

	.img-box {
		height: calc(100vw * 138 / 375);
	}
	.web-img {
		display: none;
	}
	.h5-img {
		display: block;
	}
	.chart-wraper {
		display: block;
	}

	.myleftCharts {
		/* width: calc(100vw * 540 / 1200); */
		width: auto;
		/* height: calc(100vw * 180 / 1200); */
		height: 300px;
		background: #333333;
		border-radius: 20px;
		/* padding-right: 30px; */
	}
	.myrightCharts {
		margin-left: 0%;
		margin-top: 20px;
		width: auto;
		/* width: calc(100vw * 360 / 1200); */
		/* height: calc(100vw * 180 / 1200); */
		height: 300px;
		background: #fff;
		border-radius: 20px;
		/* padding-right: 30px; */
	}
}
</style>

<style>
/* 父容器，确保溢出隐藏并设置圆角 */
.table-box {
	overflow: hidden; /* 确保溢出部分被隐藏 */
	border-radius: 6px; /* 设置整个表格的圆角 */
}

/* 设置表头的左上角和右上角圆角 */
.el-table thead tr:first-child th:first-child {
	border-top-left-radius: 6px; /* 左上角圆角 */
}

.el-table thead tr:first-child th:last-child {
	border-top-right-radius: 6px; /* 右上角圆角 */
}

.table-box .el-table th.el-table__cell {
	background: #000;
}

.table-box .el-table__header-wrapper .el-table__cell .cell {
	color: #fff !important;
	font-size: calc(100vw * 12 / 1200);
	word-break: auto-phrase;
}
@media screen and (max-width: 900px) {
	.headerg.leftTab {
		display: block !important;
	}
	.table-box .el-table__header-wrapper .el-table__cell .cell {
		color: #fff !important;
		font-size: calc(100vw * 12 / 440);
		word-break: auto-phrase;
	}
	.even-row-m {
		background-color: rgba(105, 105, 105, 1) !important; /* 浅灰色 */
	}
	.odd-row-m {
		background-color: rgba(105, 105, 105, 0.6) !important; /* 浅灰色 */
	}
	.even-row-m1 {
		background-color: rgba(15, 15, 15, 1) !important; /* 浅灰色 */
	}
	.odd-row-m1 {
		background-color: rgba(15, 15, 15, 0.8) !important; /* 浅灰色 */
	}
}

/* 奇数行背景色 */
.odd-row {
	background-color: rgba(115, 115, 115, 0.1) !important; /* 浅灰色 */
}

/* 偶数行背景色 */
.even-row {
	background-color: #ffffff !important; /* 白色 */
}
.table-box .cellcompany div,
.table-box .cellname div {
	font-weight: 600;
}
.table-box .cellcompany div,
.table-box .el-table_2_column_6 div,
.table-box .el-table_2_column_5 div,
.table-box .el-table_1_column_1 div,
.table-box .cellname div {
	text-align: left !important;
}

.table-box .el-table__cell div {
	text-align: right;
}
</style>

<style>
.content-box-pc .el-table .cell {
	color: #333;
	font-size: calc(100vw * 12 / 1200);
	line-height: calc(100vw * 14 / 1200);
	word-break: auto-phrase;
}
.content-box .cellsymbol .cell {
	color: #6e87b0 !important;
}
.content-box .cellundefined .cell {
	font-weight: bold;
}
.el-table__header-wrapper .el-table__cell .cell {
	color: #333 !important;
	font-weight: normal;
}
.el-table td.el-table__cell,
.el-table__body {
	border-bottom: 1px solid rgba(216, 216, 216, 0.3);
}

.el-table .el-table__cell {
	height: 50px;
}
.el-table__body,
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	border-bottom: none;
}
.el-table th.el-table__cell {
	background: #f7f8f9;
}

.el-table__body,
.el-table__footer,
.el-table__header {
	width: 100% !important;
}

@media screen and (max-width: 900px) {
	.el-table .el-table__cell {
		height: 40px;
	}
	.content-box-mobile .el-table .cell {
		color: #fff;
		font-size: calc(100vw * 8 / 375);
		line-height: 1;
	}
	.content-box-mobile .el-table td.el-table__cell,
	.content-box-mobile .el-table__body {
		border-bottom: 1px solid rgba(216, 216, 216, 0.3);
	}
	.content-box .el-table_1_column_1 .cell {
		font-weight: 600;
	}
}
</style>

<style scoped>
.dataera_type {
	display: flex;
	margin-top: 20px;
	margin-left: 38px;
	background: #f4f4f4;
}

.dataera_type_item {
	display: flex;
	font-size: 16px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯黑 */
	color: #000000;
	opacity: 1;
	background: #f4f4f4;
}

.dataera_type_item_name {
	background: #f4f4f4;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
	border-radius: 20px 20px 0 0;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 0px;
	margin-right: 0px;
	width: 100%;
	height: 50px;
}

.dataera_type_item_active {
	color: #f4f4f4;
}

.dataera_type_item_name_active {
	background: #000000;
	color: #f4f4f4;
}

.dataera_type_item_name_left,
.dataera_type_item_name_right {
	width: 20px;
	height: 50px;
	background: black;
}

.dataera_type_item_name_left_block,
.dataera_type_item_name_right_block {
	width: 20px;
	height: 50px;
	background: #f4f4f4;
}

.dataera_type_item_name_right_block {
	border-radius: 0 0 0 20px;
}

.dataera_type_item_name_left_block {
	border-radius: 0 0 20px 0;
}

@media screen and (max-width: 900px) {
	.dataera_type {
		width: calc(100vw - 30px);
		margin-left: 15px;
		background: #0f0f0f;
	}
	.dataera_type_item {
		padding-left: 6px;
		padding-right: 6px;
		border-radius: 10px 10px 0 0;
		height: 30px;
		background: #0f0f0f;
	}

	.dataera_type_item_name {
		background: #0f0f0f;
		font-size: 12px;
		font-weight: 600;
		display: flex;
		align-content: center;
		align-items: center;
		text-align: center;
		border-radius: 20px 20px 0 0;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
		height: 30px;
		color: white;
	}

	.dataera_type_item_active {
		color: #0f0f0f;
	}

	.dataera_type_item_name_active {
		background: #000000;
		color: #f4f4f4;
	}

	.dataera_type_item_name_left,
	.dataera_type_item_name_right {
		width: 10px;
		height: 30px;
		background: black;
	}

	.dataera_type_item_name_left_block,
	.dataera_type_item_name_right_block {
		width: 10px;
		height: 30px;
		background: #0f0f0f;
	}

	.dataera_type_item_name_right_block {
		border-radius: 0 0 0 10px;
	}

	.dataera_type_item_name_left_block {
		border-radius: 0 0 10px 0;
	}
}
</style>
