var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"js-copy js-content pd_body"},[_c('headerNav',{attrs:{"currentPath":'DataEra',"isScrolled":_vm.isScrolled}}),_c('headerTop',{staticClass:"headertop",attrs:{"type":'2',"language":this.$route.params.type}}),_c('div',{staticClass:"content-box content-box-pc content-box-mobile"},[_vm._m(0),_c('div',{staticClass:"chart-wraper margin-pc margin-mobile"},[_c('div',{staticClass:"myleftCharts",attrs:{"id":"myleftCharts"}}),_c('div',{staticClass:"table-box margin-right-pc margin-right-mobile0"},[_c('el-table',{attrs:{"data":_vm.tableRightData,"fit":"","cell-class-name":_vm.customClass,"row-class-name":_vm.tableRowClassName}},_vm._l((_vm.tableRightColumn),function(item,ind){return _c('el-table-column',{key:ind,class:{ cell_active: ind == 0 },attrs:{"prop":item.prop,"label":item.label,"min-width":_vm.isMobileDevice
									? ind == 0
										? '100'
										: ind == 1
										? '60'
										: '80'
									: ind == 0
									? '100'
									: ind == 1
									? '60'
									: '80'}})}),1)],1)]),_c('div',{staticClass:"dataera_type"},_vm._l((_vm.getTypeArray()),function(item,index){return _c('div',{key:index,staticClass:"dataera_type_item pointer",class:{ dataera_type_item_active: index == _vm.currentType - 1 },on:{"click":function($event){return _vm.handleClick(index)}}},[(index == _vm.currentType - 1)?_c('div',{staticClass:"dataera_type_item_name_left"},[_c('div',{staticClass:"dataera_type_item_name_left_block"})]):_vm._e(),_c('div',{staticClass:"dataera_type_item_name",class:{
							dataera_type_item_name_active: index == _vm.currentType - 1,
						}},[_vm._v(" "+_vm._s(item.name)+" ")]),(index == _vm.currentType - 1)?_c('div',{staticClass:"dataera_type_item_name_right"},[_c('div',{staticClass:"dataera_type_item_name_right_block"})]):_vm._e()])}),0),_c('div',{staticClass:"table-box margin-pc margin-mobile0"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"fit":"","cell-class-name":_vm.customClass,"row-class-name":_vm.tableRowClassName1}},[(!_vm.isMobileDevice)?_c('el-table-column',{attrs:{"type":"index","label":"NO.","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}],null,false,3056706777)}):_vm._e(),_vm._l((_vm.getTableColumn()),function(item,ind){return _c('el-table-column',{key:ind,class:{ cell_active: ind == 0 },attrs:{"prop":item.prop,"label":item.label,"min-width":_vm.isMobileDevice
								? ind == 0
									? '100'
									: ind == _vm.getTableColumn().length - 1
									? '80'
									: '60'
								: ind == 0
								? '200'
								: ind == _vm.getTableColumn().length - 1
								? '100'
								: '80'}})})],2)],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box margin-pc margin-mobile"},[_c('img',{staticClass:"h5-img",attrs:{"src":"https://res.metaera.hk/resources/250226/174055005967beafab49d19.jpg"}}),_c('img',{staticClass:"web-img",attrs:{"src":"https://res.metaera.hk/resources/250226/174055007867beafbe90265.jpg"}})])
}]

export { render, staticRenderFns }